import React from "react"
import { Link, graphql } from "gatsby"
import queryString from 'query-string'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SubscriptionSection from "../../components/subscription_section"

class Subscription extends React.Component {

  state = {
    iframeSrc: "",
    showPasswordPicker: false,
    showBack: false
  };

  receiveMessage(event: any) {
    if (event.data != "logged-in") {
      return;
    }
    this.setState({ ...this.state, iframeSrc: "" })
  }

  componentDidMount() {
    var referrerQueryParams = document.referrer ? queryString.parse(document.referrer.substring(document.referrer.indexOf("?"))) : {};
    if (referrerQueryParams.ref) {
      window.history.replaceState(null, null, "?ref=" + referrerQueryParams.ref);
    }

    var queryParams = location.search ? queryString.parse(location.search) : {};


    window && window.addEventListener("message", this.receiveMessage.bind(this), false);

    this.setState({
      ...this.state,
      showBack: !!queryParams.id && (typeof window !== 'undefined') && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
      iframeSrc: (queryParams.email && !queryParams.id) ? ("https://usehyperlink.com/app/?authPopup=true&email=" + queryParams.email) : null
    });
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Hyperlink" slug="/subscription/" />
        <div className="container subscription-success">
          <h1>Success!</h1>
          <div className="form">
            <div className="preview" id="preview">
              We'll email you within 24 hours once your custom domain has been setup.
        <br /><br />
              If you have any questions, email us at <a
                href="mailto:questions@usehyperlink.com">questions@usehyperlink.com</a>.
      </div>
            {this.state.showBack && <div id="back" className="submit">
              <a href="hyperlink://home" className="button">
                Back to Hyperlink!
        </a>
            </div>}
            {this.state.iframeSrc && <iframe className="password-iframe" src={this.state.iframeSrc}></iframe>}

          </div>
        </div>

      </Layout>
    )
  }
}

export default Subscription

export const pageQuery = graphql`
  query {
            site {
            siteMetadata {
            title
          }
          }
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC }) {
            edges {
            node {
            excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
    title
    description
  }
}
}
}
}
`
